<template>
  <div class="task-container">
<!--    <van-search v-model="value" placeholder="请输入搜索关键词" />-->
    <div class="pro-nums" v-if="listData.length>0">项目数量
      <span class="nums">{{result.length}}</span>
    </div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" >
      <van-list
        v-model="loading"
        :finished="finished"
        success-text="刷新成功"
        finished-text="没有更多了"
        @load="getList"
        :immediate-check="false"
      >
        <div v-for="(fa,i) in listData" :key="i">
          <div class="task-list" v-for="(item,index) in fa" :key="item.id">
            <div class="task-mid">
              <div class="task-name van-ellipsis">项目名称:{{item.name}}</div>
              <div class="task-name van-ellipsis">发布企业:{{item.merchantName}}</div>
              <div class="task-name van-ellipsis">发布时间:{{item.createTime}}</div>
              <div class="task-name van-ellipsis">薪酬:{{item.salaryRange}}</div>
              <div class="task-name van-ellipsis">项目描述:{{item.describe}}</div>
              <div class="task-type">
                {{status[item.status]}}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
    <van-empty description="没有更多数据了" v-if="!listData.length > 0" />
    <footer-menu></footer-menu>

  </div>
</template>
<script>
import footerMenu from '@/components/footer/FooterMenu'
import requestHTAPI from '@/axios/HantangTax'
export default {
  name: 'myPro',
  components: {
    footerMenu
  },
  data () {
    return {
      value: '',
      listData: [],
      result: [],
      loading: false,
      finished: false,
      refreshing: false,
      total: 0,
      formData: {
        pageNum: 1,
        pageSize: 10
      },
      status: { 0: '结束', 1: '进行中' }
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      requestHTAPI.mineProject({
        ...this.formData
      }).then(res => {
        this.loading = false
        if (res.data.code === '0000') {
          const data = res.data.data
          if (this.listData.length > 0 && data.pageNum === 1) {
            return
          }
          this.formData.pageNum = data.pageNum
          this.formData.pageNum++
          this.formData.pageSize = data.pageSize
          this.total = data.total
          this.listData.push(data.rows)
          this.result = Array.prototype.concat.apply([], this.listData)
          if (this.result.length >= this.total) {
            this.finished = true
            // this.$notify({ type: 'danger', message: '没有更多数据了' })
          }
        } else {
          this.finished = true
          this.$notify({ type: 'danger', message: res.data.message })
        }
      })
    },
    onRefresh () {
      // 清空列表数据
      if (this.refreshing) {
        this.formData.pageNum = 1
        this.listData = []
        this.refreshing = false
      }
      this.finished = false
      this.loading = true
      this.getList()
    },
    taskInfo (index) {
      this.$router.push({
        name: 'TaskInfo',
        query: {
          id: index
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
  .task-container{
    height: calc(100% - 100px);
  }
  .taskbox{
    margin-top: 40px;
    overflow: hidden;
  }
  .task-list{
    background: #fff;
    height: 220px;
    overflow: hidden;
    margin: 0px 20px 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0vw 15px 30px -15px rgb(187, 187, 187 , 50%);
  }

  .task-mid{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    box-sizing: border-box;
    text-align: left;
    justify-content: space-around;
    flex-flow: column nowrap;
    flex: 1;
    position: relative;
    .task-name{
      font-size: 26px;
      color: #333;
      padding-right:100px;
      position: relative;
      /*width: 500px;*/
      /*overflow: hidden;*/
      /*text-overflow: ellipsis;*/
      /*white-space:nowrap;*/
    }
    .task-type{
      position: absolute;
      right: 10px;
      top: 10px;
      color: #1989fa;
    }
  }
  .pro-nums{
    text-align: left;
    padding: 10px 40px;
    font-size: 30px;
    font-weight: bold;
    .nums{
      color: #1989fa;
    }
  }

</style>
